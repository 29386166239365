import React from "react";
import ReactDOM from "react-dom";

/* global document */

const App = () => {
  return (
    <div>
      <h1>Excel Add-In App</h1>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
